import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { ApiChangePassword } from '@app/preferences/models/profile';
import { catchError, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService extends ApiService {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public forgotPassword(username: string): Observable<string> {
    return this.post('uc/forgot-password', {
      username: username,
    }).pipe(
      map((data: { message?: string; error?: string }) => {
        return data.message ?? data.error;
      }),
      catchError((error) => {
        return of(error.error.message ?? error.error.error);
      })
    );
  }

  public resetPassword(
    password: string,
    password_confirmation: string,
    user_id: string,
    signature: string
  ): Observable<string> {
    return this.post('uc/reset-password', {
      password: password,
      password_confirmation: password_confirmation,
      user_id: user_id,
      signature: signature,
    }).pipe(
      map((data: { message?: string; error?: string }) => {
        return data.message ?? data.error;
      }),
      catchError((error) => {
        return of(error.error.message ?? error.error.error);
      })
    );
  }

  public validateResetPasswordLink(expires: string, user: string, signature: string): Observable<boolean> {
    return this.get('uc/reset-password', {
      params: new HttpParams({ fromObject: { expires: expires, user: user, signature: signature } }),
    }).pipe(
      map(() => {
        return true;
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  public changePassword(changePassword: ApiChangePassword) {
    return this.post(`users/{me}/update-password`, {
      current_password: changePassword.currentPassword,
      new_password: changePassword.newPassword,
      new_password_confirmation: changePassword.confirmPassword,
    });
  }
}
